<template>
  <ul v-if="finalCrumbs.length" class="breadcrumb size-xs">
    <li>
      <NuxtLink to="/">{{ useMicroCopy("site.name") }}</NuxtLink>
    </li>
    <li v-for="(crumb, index) in finalCrumbs" :key="index">
      <template v-if="index < finalCrumbs.length - 1 && crumb.path">
        <NuxtLink :to="strippedURL(crumb.path)">{{ crumb.title }}</NuxtLink>
      </template>
      <template v-else>
        {{ crumb.title }}
      </template>
    </li>
  </ul>
</template>

<script setup>
const config = useRuntimeConfig();
const props = defineProps({
  pageTitle: {
    type: String,
    required: false,
    default: "",
  },
});

const route = useRoute();
let path = route.path;
path = path[path.length - 1] === "/" ? path.slice(0, -1) : path;
const params = path.startsWith("/")
  ? path.substring(1).split("/")
  : path.split("/");
const crumbs = reactive([]);
const finalCrumbs = reactive([]);
let crumbPath = "";
const seoCrumbs = [
  {
    "@type": "ListItem",
    position: 1,
    name: useMicroCopy("site.name"),
    item: config.public["baseURL"],
  },
];

params?.forEach(async(param) => {
  crumbPath = `${crumbPath}/${param}`;
  crumbs.push({
    path: crumbPath,
  });
});

crumbs?.forEach(async(item, index) => {
  await useCustomAsyncStoryblok("", {
    version: config.public["storyblokVersion"],
    by_slugs: `${config.public["storyblokCountryPrefix"]}${crumbs[index].path}/`,
  }).then((result) => {
    let finalTitle;
    let breadcrumbLabel = result.value.stories[0]?.content?.breadcrumb_label;
    if (index === params.length - 1 && props.pageTitle) {
      finalTitle = props.pageTitle;
    } else if(breadcrumbLabel) {
      finalTitle = breadcrumbLabel;
    } else {
      let title = crumbs[index].path.split("/").slice(-1)[0].replace(/-/g, " "); 
      title = title[0].toUpperCase() + title.slice(1).toLowerCase();
      finalTitle = title;
    }
    
    finalCrumbs.push({...item});
    setTimeout(() => {
      if(finalCrumbs[index]) {
        finalCrumbs[index].title = finalTitle;
      }
      seoCrumbs.push({
        "@type": "ListItem",
        position: index + 2,
        name: finalTitle,
        item: config.public["baseURL"] + crumbs[index].path,
      });
    }, 200);
  });
});

onMounted(() => {
  let seoScript = document.createElement("script");
  seoScript.setAttribute("type", "application/ld+json");
  seoScript.innerHTML = `{"@context": "https://schema.org", "@type": "BreadcrumbList", "itemListElement": ${JSON.stringify(
    seoCrumbs
  )}}`;
  document.head.appendChild(seoScript);
});
</script>

<style lang="scss" scoped>
.breadcrumb {
  padding: 1.5rem col-span() 0;
  text-align: center;
  @include truncate;
  color: var(--text-70);
  @include for-tablet-landscape-up {
    padding-top: 2.5rem;
  }
  a {
    color: var(--text-70);
    text-decoration: none;
  }
  li {
    display: inline;
  }
  li:not(:last-child):after {
    content: " > ";
    padding: 0 0.2rem;
  }
}
.facility-bar + .breadcrumb {
  padding-top: 1.25rem;
}
</style>
